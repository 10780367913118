@import "../../../node_modules/antd/dist/antd.compact.css";
@import "~antd/dist/antd.css";

.primary-color {
  color: #f09819 !important;
}

.submit-fixed{
  width: 108px;
}

.submit-secondary-spaced{
  margin-right: 30px;
}