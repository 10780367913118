.main-container {
  height: 100vh;
}

.box {
  background-color: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 100%;
  text-align: center;
  min-height: 320px;
  display: flex;
}

.full-size-container {
  width: 100%;
}

.sign-up {
  font-weight: bold;
}

.to-sign {
  cursor: pointer;
}

.loginCard {
  width: 60%;
}

.button-submit {
  font-weight: bold;
  font-size: 15px;
  background-color: #f09819;
  border-color: #f09819;
}
.button-submit:hover,
.button-submit:active,
.button-submit:focus {
  font-weight: bold;
  font-size: 15px;
  background-color: #fff;
  color: #f09819;
  border-color: #f09819;
}

.button-submit-secondary {
  font-weight: bold;
  font-size: 15px;
  background-color: #fff;
  color: #f09819;
  border-color: #f09819;
}
.button-submit-secondary:hover,
.button-submit-secondary:active,
.button-submit-secondary:focus {
  font-weight: bold;
  font-size: 15px;
  background-color: #f09819;
  border-color: #f09819;
}

.button-sign-up {
  font-size: 15px;
  color: #f09819;
}
.button-sign-up:hover,
.button-sign-up:active,
.button-sign-up:focus {
  color: #f0c919;
}

@media only screen and (max-width: 991px) {
  .loginCard {
    width: 100%;
  }
}

.welcome {
  background: #f09819; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #edde5d,
    #f09819
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #edde5d,
    #f09819
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.shadowCardLogin {
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
  min-height: 350px;
}
