/* @import '../node_modules/antd/dist/antd.light.css'; */
@import "../node_modules/antd/dist/antd.compact.css";
@import "~antd/dist/antd.css";

/* Custom styles */
/* .ant-input,
.ant-input-number-input,
.ant-input-number-input-wrap {
  border-right: 0 !important;
  border-top: 0 !important;
} */

.center-column {
  text-align: center;
}

.ant-row {
  margin: 0 !important;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.ant-drawer .ant-card {
  background-color: white !important;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: #1890ff !important;
  background: #fff !important;
  border-color: #f0f0f0 !important;
  border-bottom: 1px solid #fff !important;
}

.box {
  background-color: lightgrey;
  color: #fff;
  padding: 0;
  font-size: 150%;
}

.wrapper {
  margin-bottom: 20px;
  width: 900px;
  height: 900px;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(5, 180px);
  grid-template-rows: repeat(5, 180px);
  grid-auto-flow: row;
}

.span-wrapper {
  width: 100%;
  height: 50%;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(5, 20%);
  grid-template-rows: repeat(1, 50%);
  grid-auto-flow: column;
}

.grid-container {
  display: flex;
  align-content: flex-start;
  width: 100vw;
}

.ideas-options {
  width: 100%;
  min-height: 400px;
  display: block;
  border-left: lightgrey 1px solid;
  background-color: white;
}

.droppable {
  width: 100%;
  height: 100%;
  display: block;
  border-left: lightgrey 1px solid;
  border-bottom: lightgrey 1px solid;
  box-sizing: border-box;
  background-color: white;
}

.span-droppable {
  width: 100%;
  height: 30px;
  padding: 2px;
  text-align: center;
  display: block;
}

.scroll-hidder-child {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: -15px;
  overflow: auto;
  box-sizing: border-box;
}

/* .ant-drawer-body {
  overflow: scroll !important;
} */

/* html {
  overflow: scroll !important;
} */

/* ::-webkit-scrollbar {
  width: 0px !important;
  background: transparent !important; /* make scrollbar transparent */
/*} */

.scroll-hidder-parent {
  overflow: hidden;
  position: relative;
  padding: 5px;
  box-sizing: border-box;
}

.scroll-child {
  padding: 5px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: -15px;
  overflow: scroll;
  box-sizing: border-box;
  height: 100%;
}

.all-ideas-list {
  min-height: 400px;
  height: 100vh;
  overflow: hidden;
  position: relative;
  padding: 10px;
  background-color: white;
  box-sizing: border-box;
}

.clickable-col {
  padding: 10px 10px 10px 10px !important;
  margin: 0px 0px 0px 0px !important;
}

.ideas-list {
  height: 20vh;
  overflow: hidden;
  position: relative;
  padding: 5px;
  background-color: white;
  box-sizing: border-box;
  overflow-y: scroll; /* Add the ability to scroll */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ideas-list::-webkit-scrollbar {
  display: none;
}

.draggable {
  height: 100px;
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}

.inProgress {
  position: absolute;
  width: 200px;
  height: 200px;
  left: 0;
  top: 10;
  background-color: #eeeeee;
  border-right: 2px dotted red;
}

.drag-container {
  text-align: center;
}

.head {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: #cccccc;
  width: 100%;
}
/* END of Custom styles */

/* Start Ant design style overriding */
.ant-layout-header {
  background: #fff;
}

.ant-card-body {
  padding: 1em !important;
}

.ant-descriptions-item-content {
  background: white !important;
}

.ant-col {
  width: 100% !important;
}

.ant-input {
  width: "100%" !important;
}
/* End Ant design style overriding */

.App {
  text-align: center;
}

.gutter-box {
  padding: 8px 0;
  background: #00a0e9;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff !important;
  min-height: 3m;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fixed-widgets {
  position: fixed;
  right: 32px;
  bottom: 102px;
  z-index: 2147483640;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
  font-size: 45px;
  color: #1890ff;
}

.idea_card_draggable {
  text-align: center;
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
}

.saveIcon {
  background-color: #fff;
}

.ant-form-item-label {
  white-space: normal;
  margin-right: 12px;
  display: inline-block;
  line-height: 12px;
  width: 50px;
}

.center-element {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 50%;
  height: 30%;
  margin: auto;
}

.green-button {
  border-color: #71cf42 !important;
  color: #71cf42 !important;
}

.issues-body {
  background-color: white !important;
}

.draggable-card {
  padding: 0;
  min-height: 50px;
  margin: 0 0 8px 0;
  color: black;
  width: 100%;
  background-color: transparent !important;
}

.isdragging-draggable-card {
  padding: 0px;
  height: 0;
  margin: 0;
  color: black;
  background-color: white;
  width: 100%;
}

.draggable-inner-span {
  display: inline-block;
  width: 100%;
  padding: 10px;
}

.isdraggingOver-inner-span {
  display: inline-block;
  border-bottom: 0.5px outset black;
  width: 100%;
  padding: 10px;
}

.isdragging-inner-span {
  display: inline-block;
  width: 100%;
  padding: 10px;
  opacity: 0.5;
}

.kanban-card {
  margin: 8px;
  border-radius: 15px 30px 30px 5px;
}

.kanban-card > .ant-card-head {
  text-align: center;
}

.ant-btn .anticon.anticon-plus {
  vertical-align: middle;
  padding-bottom: 2.5px;
}

.infinite-container {
  min-height: fit-content;
  max-height: 500px;
  padding: 8px 24px;
  overflow: auto;
}

.content-box {
  padding: 30px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 5px; /* 5px rounded corners */
  background-color: #ffffff !important;
  height: fit-content;
  min-height: 100vh;
}

.css-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  color: black;
}

.css-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 80px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

.calendar-box {
  padding: 30px;
  margin-left: 50px;
  margin-top: 20px;
  margin-right: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 5px; /* 5px rounded corners */
  background-color: #ffffff !important;
  min-height: fit-content;
  max-height: 82vh;
  padding: 8px 24px;
  overflow: auto;
}

.calendar-card {
  margin-top: 20px;
  margin-right: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  border-radius: 5px; /* 5px rounded corners */
  background-color: #ffffff !important;
  max-height: 82vh;
  overflow: auto;
}
